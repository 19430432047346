import React, { useState } from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';

import { CARD_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faToggleOn } from '@fortawesome/pro-light-svg-icons';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import {
  Paper,
  Button,
  Grid,
  Hidden,
  CircularProgress,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import useStyles from './styles';

const ActivateCardView = ({ passId, title }) => {
  const styles = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const onSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setLoading(true);
    if (isChecked) {
      const getEnableService = await import('services/passes/enable-pass-service');
      const { enableLoyaltyPass } = getEnableService.default({
        stateContext,
        noticationContext,
      });
      await enableLoyaltyPass({ passId });
      navigate(`${CARD_ROUTE}?passId=${passId}`);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
  <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
   >
    <Grid item xs={12} md={10} xl={8}>
      <Paper className={styles.paper} elevation={4}>
      <form autoComplete="off" id="activate-loyalty-card">
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={10} className={styles.fourTopBotMargin}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={styles.headerGreen}>
                Activate to use
                <FontAwesomeIcon icon={faToggleOn} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                    Enable and use in your loyalty scheme
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={10}>
              <FormControl
              error={error}
              >
              <FormLabel
                htmlFor="activate-loyalty-card"
                className={styles.pickerLabel}>
                {error ? 'Please check this box to enable the loyalty card for use' : `${isChecked ? '' : 'Please check this box to continue.'}`}
                </FormLabel>
                <FormControlLabel
                 control={
                   <Checkbox
                   id="activate-loyalty-card"
                    color="secondary"
                    checked={isChecked}
                    aria-label="agree to activate loyalty card"
                    onChange={() => {
                      setIsChecked(!isChecked);
                      if (error) {
                        setError(false);
                      }
                    }}
                    name="checkedG"
                    />}
                 label={`Enable loyalty card: ${title}`}
                 />
                 <FormHelperText>{`${
                   error
                     ? 'Required. Enable the loyalty card to use it. You can only share activated loyalty cards with your cutomers.'
                     : 'Enable the loyalty card to use it. You can only share activated loyalty cards with your cutomers.'
                 }`}</FormHelperText>
               </FormControl>
          </Grid>

           <Grid item xs={12} sm={10} className={styles.noTopMargin}>
             <Hidden smUp>
               <Button
                 variant="contained"
                 color="primary"
                 className={styles.formbutton}
                 onClick={onSubmit}
                 type="submit"
                 fullWidth
                 aria-label="activate card"
                 disabled={loading}
                 endIcon={loading
                   ? <CircularProgress className={styles.submitLoading}/>
                   : (
                     <FontAwesomeIcon icon={faPlay} className={styles.submitIcon}/>
                   )
                 }
                 >
                Activate
              </Button>
             </Hidden>
             <Hidden xsDown>
               <Button
                 variant="contained"
                 color="primary"
                 className={styles.formbutton}
                 onClick={onSubmit}
                 type="submit"
                 fullWidth
                 aria-label="activate card"
                 disabled={loading}
                 endIcon={loading
                   ? <CircularProgress className={styles.submitLoading}/>
                   : (
                     <FontAwesomeIcon icon={faPlay} className={styles.submitIcon}/>
                   )
                 }
                 >
                Activate Loyalty card
              </Button>
             </Hidden>
           </Grid>
        </Grid>
      </form>
      </Paper>
    </Grid>
  </Grid>
  );
};

ActivateCardView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default ActivateCardView;
